import Vue from "vue";
import Router from "vue-router";
import Store from "../store/index.js";

const router = new Router({
  routes: [
    {
      path: "/",
      name: "Home",
      component: () =>
        import(/* webpackChunkName: "home" */ "../app/login/login.vue"),
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "login" */ "../app/login/login.vue"),
    },
    {
      path: "/registrieren",
      name: "Registrieren",
      component: () =>
        import(
          /* webpackChunkName: "registrieren" */ "../app/login/register.vue"
        ),
    },
    {
      path: "/spiele",
      name: "Spiele",
      meta: {
        auth: true,
      },
      component: () =>
        import(/* webpackChunkName: "spiele" */ "../app/spiele/spiele.vue"),
    },
    {
      path: "/teams",
      name: "Teams",
      meta: {
        auth: true,
      },
      component: () =>
        import(/* webpackChunkName: "teams" */ "../app/teams/teams.vue"),
    },
    {
      path: "/team/:id",
      name: "Team",
      meta: {
        auth: true,
      },
      component: () =>
        import(/* webpackChunkName: "team" */ "../app/teams/team.vue"),
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    const user = Store.getters.user;

    if (!user.isLoggedIn) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

Vue.use(Router);

export default router;

<template>
  <div id="app">
    <Menu />
    <router-view />
  </div>
</template>

<script>
import Menu from "@/app/menu/menu.vue";
export default {
  components: {
    Menu,
  },
};
</script>

<style>
/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,700;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@900&display=swap");

/* Body */
body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
}
body {
  background: #f7f7f7;
}

#app {
  padding-bottom: 2%;
}

/* Texts */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Raleway, sans-serif;
  font-weight: 900;
  text-transform: uppercase;
  color: #141414;
}
h4 {
  padding-top: 20px;
  padding-bottom: 19px;
  font-size: 24px;
  text-transform: uppercase;
}

i {
  display: inline-block;
}

.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
}

/* Forms */
.form-container .item {
  padding-bottom: 15px;
}

.form-container.item label {
  margin-bottom: 10px;
  cursor: pointer;
  display: block;
  font-weight: 400;
}

.form-container .item span {
  padding-bottom: 7px;
  display: block;
  font-size: 14px;
  color: #666;
  line-height: normal;
  font-weight: 400;
}

.form-container .item span i {
  font-style: normal;
  color: #d32f2f;
}

input {
  margin: 0;
  padding: 0;
  vertical-align: middle;
}

input[type="text"],
input[type="password"],
input[type="email"] {
  padding: 15px 10px;
  border: 1px solid transparent;
  width: 100%;
  background: #fff;
  font-size: 14px;
  color: #666;
  line-height: normal;
  outline: 0;
}
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

a {
  color: #ffa000;
  text-decoration: none;
  outline: 0;
  transition: all 0.4s;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.player-hockey-wrap .circle-item path {
  stroke: #fc0;
}
.player-hockey-wrap .circle-item {
  width: 50%;
  float: left;
}
.player-hockey-wrap .circle-item .circle-bar {
  width: 42px;
  height: 42px;
  position: relative;

  border: solid #fc0;
  border-radius: 100%;
}

.player-hockey-wrap .circle-item .progressbar-text {
  font-size: 15px;
  font-family: Montserrat;
  color: #666 !important;
}
.player-hockey-wrap .circle-item .text {
  padding-left: 6px;
  font-size: 15px;
  text-transform: uppercase;
  color: #666;
  font-family: Montserrat, sans-serif;
}
</style>

<template>
  <div>
    <ul class="main-menu nav">
      <li
        v-for="item in showLoggedIn ? loggedIn : notLoggedIn"
        :key="item.name"
      >
        <!-- DISABLED LINKS -->
        <span v-if="item.disabled" style="color: maroon">{{ item.name }}</span>

        <!-- NORMAL LINKS -->
        <a v-if="!item.disabled" :href="'#' + item.link"
          ><span>{{ item.name }}</span></a
        >
      </li>
      <li>
        <a href="/"
          ><span>
            <i
              v-if="showLoggedIn"
              class="far fa-user user"
              aria-hidden="true"
            ></i></span
        ></a>
      </li>
    </ul>
  </div>
</template>

<style>
.nav {
  display: block;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.nav > li > a,
.nav > li > span {
  position: relative;
  display: block;
}

.main-menu {
  text-align: right;
}
.main-menu > li {
  display: inline-block;
}
.main-menu > li a,
.main-menu > li span {
  padding: 0 17px;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
}
.main-menu > li > a > span {
  padding: 41px 0;
  position: relative;
  display: block;
}
.main-menu > li.active a span:before {
  opacity: 1;
  height: 2px;
}
.main-menu > li > a > span:before {
  content: "";
  width: 100%;
  height: 2px !important;
  background: #fc0;
  bottom: 30px;
  left: 0;
  position: absolute;
  opacity: 0;
  transition: all 0.4s;
}
.nav > li > a:focus,
.nav > li > a:hover {
  text-decoration: none;
}
.main-menu > li:hover > a {
  background: 0 0;
  color: #fc0;
}
.main-menu > li a:hover span:before {
  opacity: 1;
  height: 2px;
}
.main-menu > li > a > span:before {
  content: "";
  width: 100%;
  height: 2px !important;
  background: #fc0;
  bottom: 30px;
  left: 0;
  position: absolute;
  opacity: 0;
  transition: all 0.4s;
}

.user {
  font-size: 20px;
  color: #fff;
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.main-menu-wrap .navbar {
  margin-bottom: 0;
}
.main-menu-wrap .navbar-collapse {
  padding-right: 0;
  padding-left: 0;
}

@media (min-width: 768px) {
  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}
</style>

<script>
export default {
  props: ["showLoggedIn"],
  data() {
    return {
      loggedIn: [
        {
          name: "start",
          link: "/",
        },
        {
          name: "Spiele",
          link: "/spiele",
        },
        {
          name: "Teams",
          link: "/teams",
        },
        {
          name: "Tippen",
          link: "/",
          disabled: true,
        },
        {
          name: "Rangliste",
          link: "/",
          disabled: true,
        },
      ],
      notLoggedIn: [
        // {
        //   name: "start",
        //   link: "/",
        // },
      ],
    };
  },
};
</script>

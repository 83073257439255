import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

import { API_URL } from "./settings.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    error: "",
    user: {
      firstName: "",
      lastname: "",
      token: "",
      isLoggedIn: false,
    },
    teams: [],
    matches: [],
  },
  mutations: {
    setError(state, error) {
      state.error = error;
    },
    setUser(state, user) {
      state.user = user;
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setMatches(state, matches) {
      state.matches = matches;
    },
  },
  actions: {
    async loadTeams({ commit }) {
      commit("setIsLoading", true);

      const config = {
        headers: {
          token: this.state.user.token,
        },
      };

      const url = `${API_URL}/teams`;

      try {
        const result = await axios.get(url, config);

        if (result.data.status && result.data.status === "error") {
          console.log("Found error: " + result.data.errorMessage);
          commit("setError", result.data.errorMessage);
          commit("setIsLoading", false);
          return;
        }

        commit("setError", null);
        commit("setTeams", result.data.teams);
      } catch (error) {
        console.log(error);
      }

      commit("setIsLoading", false);
    },
    async loadMatches({ commit }, urlData) {
      commit("setIsLoading", true);
      const config = {
        headers: {
          token: this.state.user.token,
        },
      };
      const url = `${API_URL}/matches?spieltag=${urlData}`;

      try {
        const result = await axios.get(url, config);
        if (result.data.status && result.data.status === "error") {
          console.log("Found error: " + result.data.errorMessage);
          commit("setError", result.data.errorMessage);
          commit("setIsLoading", false);
          return;
        }

        commit("setError", null);
        commit("setMatches", result.data.matches);
      } catch (error) {
        this.status = error;
      }

      commit("setIsLoading", false);
    },
    async login({ commit }, loginData) {
      commit("setIsLoading", true);
      const url = `${API_URL}/login/${loginData.email}/${loginData.password}`;
      try {
        const response = await axios.get(url);

        const r = response.data;

        if (r.status !== "success") {
          commit("setUser", {
            isLoggedIn: false,
          });

          commit("setError", { level: "warning", text: r.errorMessage });
        }

        if (r.status === "success") {
          r.user.isLoggedIn = true;

          commit("setError", null);
          commit("setUser", r.user);
        }
      } catch (error) {
        commit("setError", { level: "danger", text: error.message });
      }

      commit("setIsLoading", false);
      return false;
    },
  },
  getters: {
    user: (state) => state.user,
    isLoading: (state) => state.isLoading,
    teams: (state) => state.teams,
    matches: (state) => state.matches,
    error: (state) => state.error,
  },
});

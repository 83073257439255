<template>
  <div
    class="main-menu-wrap sticky-menu"
    data-id="0"
    style="position: relative; top: 0px"
  >
    <div class="container">
      <a href="index.html" class="custom-logo-link"
        ><img
          src="https://www.kicktipp.de/snaddibuli/mdesign/images/134305.png"
          alt="logo"
          class="custom-logo"
      /></a>
      <button
        type="button"
        class="collapse navbar-toggle collapsed"
        data-target="#team-menu"
        aria-expanded="false"
      >
        <span class="sr-only">Toggle navigation</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
      </button>
      <nav class="navbar">
        <div class="navbar-collapse" id="team-menu">
          <Items :showLoggedIn="isLoggedIn" />
        </div>
      </nav>
    </div>
  </div>
</template>

<style>
.main-menu-wrap {
  background: #141414;
  width: 100%;
  position: relative;
  z-index: 100;
}
.main-menu-wrap .custom-logo-link {
  height: 102px;
  display: flex;
  float: left;
  position: relative;
}
.main-menu-wrap .custom-logo-link:before {
  content: "";
  background-color: #0a0a0a;
  top: 0;
  bottom: 0;
  right: -75%;
  left: -10000%;
  position: absolute;
  transform: skew(-10deg);
  z-index: 0;
}
.main-menu-wrap .custom-logo-link img {
  margin: auto 0;
  position: relative;
  width: 50px;
}
</style>

<script>
import Items from "@/app/menu/items.vue";
export default {
  components: { Items },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    isLoggedIn() {
      return this.user.isLoggedIn;
    },
  },
};
</script>
